import posthog from 'posthog-js'
import { getBootstrapDataClient } from 'lib/posthog/utils'

const initializePosthog = (routerType: 'appRouter' | 'pagesRouter') => {
	const bootstrap = getBootstrapDataClient()
	posthog.init(process.env.POSTHOG_PROJECT_API_KEY, {
		api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
		loaded: (posthog) => {
			if (process.env.NODE_ENV === 'development') posthog.debug()
		},
		// App Router needs to handle pageview capturing manually so this
		// needs to be set to false
		capture_pageview: routerType !== 'appRouter',
		bootstrap,
	})
}

export default initializePosthog
