import Cookies from 'js-cookie'

export const POSTHOG_BOOTSTRAP_COOKIE_KEY = 'ph_bootstrap_data'

export const parseBootstrapData = (jsonData: string) => {
	try {
		return JSON.parse(jsonData)
	} catch (e) {
		return null
	}
}
export const getBootstrapDataClient = () => {
	const maybeBootstrapData = Cookies.get(POSTHOG_BOOTSTRAP_COOKIE_KEY)
	return parseBootstrapData(maybeBootstrapData)
}
